import { MessageCircle } from "lucide-react";
import React from "react";
import { navigateToWhatsapp } from "../utils/generic";

const SupportBubble: React.FC = () => {
  return (
    <button
      onClick={navigateToWhatsapp}
      className="fixed bottom-5 hover:scale-105 transition-all flex items-center justify-center right-5 bg-green rounded-full w-14 h-14 z-50"
    >
      <MessageCircle size={32} className="text-white" />
    </button>
  );
};

export default SupportBubble;
