import React, { useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import HalfWithForm from "../components/HalfWithForm";
import { Link } from "react-router-dom";
import { useAuth, withoutAuth } from "../contexts/authContext";
import * as Yup from "yup";
import getValidationErrors from "../utils/getYupValidationErrors";
interface FormProps {
  email: string;
  password: string;
}

const Schema = Yup.object().shape({
  email: Yup.string().required("Obrigatório").email("Email inválido"),
  password: Yup.string()
    .min(6, "Senha deve ter no mínimo 6 caracteres")
    .required("Senha obrigatória"),
});

const Login: React.FC = () => {
  const { login } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState({} as FormProps);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError({} as FormProps);

    const data = {
      password,
      email,
    };

    try {
      await Schema.validate(data, {
        abortEarly: false,
      });

      await login({
        email,
        password,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newError = getValidationErrors(err);
        setError(newError as FormProps);
      }
    }
    setLoading(false);
  };

  return (
    <HalfWithForm>
      <h1 className="text-center text-3xl my-2 font-extrabold">Login</h1>
      <p className="text-center mb-16 text-xl mt-4">
        Para acessar sua conta, preencha os campos abaixo
      </p>

      <Input
        name="Email"
        onChange={setEmail}
        placeholder="Digite seu email"
        value={email}
        error={error.email}
      />
      <Input
        name="Senha"
        onChange={setPassword}
        placeholder="Digite sua senha"
        value={password}
        isPassword
        error={error.password}
      />
      <Button name={loading ? "Carregando..." : "Entrar"} disabled={loading} onClick={handleSubmit} />

      <Link
        to="/forgot-password"
        className="text-center text-gray mt-16 underline decoration-solid"
      >
        Esqueceu sua senha?
      </Link>
      <p className="text-center mt-4">
        Ainda não possui uma conta?{" "}
        <Link to="/">
          <span className="text-primary font-bold">Cadastre-se</span>
        </Link>
      </p>
    </HalfWithForm>
  );
};

export default withoutAuth(Login);
