import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./pages/Login";
import CompleteRegister from "./pages/CompleteRegister";

import { Provider as AlertProvider, positions, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import { AuthProvider } from "./contexts/authContext";
import SupportBubble from "./components/SupportBubble";
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Dashboard/Profile";
import Leads from "./pages/Leads";
import Company from "./pages/Dashboard/Company";
import ApiKeys from "./pages/Dashboard/ApiKeys";
import CreateOrUpdateAttendant from "./pages/Dashboard/CreateOrUpdateAttendant";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/complete-register/:id",
    element: <CompleteRegister />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/dashboard/attendant",
    element: <CreateOrUpdateAttendant />,
  },
  // {
  //   path: "/dashboard/support",
  //   element: <Support />,
  // },
  {
    path: "/dashboard/profile",
    element: <Profile />,
  },
  {
    path: "/dashboard/leads",
    element: <Leads />,
  },
  {
    path: "/dashboard/company",
    element: <Company />,
  },
  {
    path: "/dashboard/api-keys",
    element: <ApiKeys />,
  }
]);

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <AuthProvider>
        <SupportBubble />
        <RouterProvider router={router} />
      </AuthProvider>
    </AlertProvider>
  );
}

export default App;
