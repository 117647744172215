import React from "react";

import LogoIcon from "../assets/logo-icon.svg";
import LogoText from "../assets/logo-text.svg";

interface HalfWithFormProps {
  children: React.ReactNode;
}

const HalfWithForm = ({ children }: HalfWithFormProps) => {
  return (
    <main className="flex flex-col min-h-screen lg:flex-row lg:h-screen">
      <div className="flex flex-col flex-1 align-center relative justify-center p-4 lg:p-4 bg-primary lg:w-[40%] lg:flex-none">
        <img className="w-8 h-8 lg:w-14 lg:h-14 mx-auto" src={LogoIcon} alt="" />
        <h1 className="text-center text-white text-xl lg:text-4xl my-1 lg:my-2 font-extrabold">
          Bem vindo ao
        </h1>
        <img className="w-24 mx-auto h-6 lg:w-32 lg:h-12" src={LogoText} alt="" />
      </div>

      <div className="flex flex-col height-full flex-1 align-center justify-center px-6 py-10 lg:p-24 lg:w-[60%] lg:overflow-y-auto">
        {children}
      </div>
    </main>
  );
};

export default HalfWithForm;
