import { Button } from "@/components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/Dialog";
import { Input } from "@/components/ui/Input";
import { Label } from "@/components/ui/Label";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { UserPhone } from "../../../../dtos/userPhone";
import api from "../../../../services/api";
import getValidationErrors from "../../../../utils/getYupValidationErrors";

interface CreateOrUpdateClientAccessProps {
  currentPhone: UserPhone;
  isOpen: boolean;
  setState: (state: boolean) => void;
  onSubmit: () => void;
}

interface FormProps {
  name: string;
  login: string;
  password: string;
}

const CreateOrUpdateClientAccess = ({
  isOpen,
  setState,
  currentPhone,
  onSubmit,
}: CreateOrUpdateClientAccessProps) => {
  const alert = useAlert();

  const Schema = Yup.object().shape({
    name: Yup.string().required("Obrigatório"),
    login: Yup.string().required("Obrigatório"),
    password: currentPhone.clientAccess
      ? Yup.string()
      : Yup.string()
          .min(6, "A senha deve ter no mínimo 6 caracteres")
          .required("Obrigatório"),
  });

  const [name, setName] = useState(currentPhone.clientAccess?.name || "");
  const [login, setLogin] = useState(currentPhone.clientAccess?.login || "");
  const [password, setPassword] = useState<string>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FormProps>({} as FormProps);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        name,
        login,
        password,
      };

      await Schema.validate(data, { abortEarly: false });

      if (currentPhone.clientAccess) {
        await api.patch(`/user-phone/client-access/${currentPhone._id}`, data);
      } else {
        await api.post(`/user-phone/client-access/${currentPhone._id}`, data);
      }

      alert.success("Acesso do cliente salvo com sucesso");

      onSubmit();
      setState(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const newError = getValidationErrors(error) as FormProps;
        setError(newError as FormProps);
      } else {
        alert.error(
          "Erro ao salvar acesso do cliente. Tente novamente mais tarde"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setName(currentPhone?.clientAccess?.name || "");
      setLogin(currentPhone?.clientAccess?.login || "");
    }
  }, [currentPhone.clientAccess, isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={setState}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Gerenciar acesso</DialogTitle>
          <DialogDescription>
            Insira os dados de acesso ao atendente pelo <Link className="text-primary" to="/dashboard/company">White Label</Link>
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-3">
        <div className="space-y-1">
          <Label htmlFor="name">Nome</Label>
          <Input
            id="name"
            value={name}
            placeholder="Digite o nome do cliente"
            className="col-span-3"
            onChange={(e) => setName(e.target.value)}
            error={error.name}
          />
        </div>

        <div className="space-y-1">
          <Label htmlFor="login">Login</Label>
          <Input
            id="login"
            value={login}
            placeholder="Digite o login do cliente"
            className="col-span-3"
            onChange={(e) => setLogin(e.target.value)}
            error={error.login}
          />
        </div>

        <div className="space-y-1">
          <Label htmlFor="password">Senha</Label>
          <p className="text-xs text-gray">
            {currentPhone.clientAccess
              ? "Ao alterar a senha, informe a nova senha ao seu cliente"
              : ""}
          </p>
          <Input
            id="password"
            value={password}
            placeholder={currentPhone.clientAccess ? "••••••" : "Digite a senha do cliente"}
            className="col-span-3"
            onChange={(e) => setPassword(e.target.value)}
            error={error.password}
          />
        </div>
        </div>

        <DialogFooter>
          <Button onClick={handleSubmit} disabled={loading} type="submit">
            Salvar acesso
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateOrUpdateClientAccess;
