import { Button } from "@/components/ui/Button";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/Drawer";
import { ScrollArea } from "@/components/ui/ScrollArea";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/Sheet";
import {
  Building,
  CircleUserRound,
  FlaskConical,
  Home,
  KeyRound,
  Link as LinkIcon,
  LogOut,
  MessageCircle,
  MoreVertical,
  OctagonAlert,
  PanelLeft,
  VerifiedIcon
} from "lucide-react";
import { useMemo, useState, useImperativeHandle, forwardRef } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "@/contexts/authContext";
import LogoIcon from "../../../assets/logo-icon.svg";

interface SidebarProps {
  children: React.ReactNode;
  title: string;
  actions?: React.ReactNode;
}

export interface SidebarRef {
  closeDrawer: () => void;
}

const Sidebar = forwardRef<SidebarRef, SidebarProps>(({ children, title, actions }, ref) => {
  const { logout, session, profile } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const currentPath = window.location.pathname;

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  useImperativeHandle(ref, () => ({
    closeDrawer
  }));

  const trialDaysRemaining = useMemo((): string | null => {
    if (profile?.product_id.includes("TRIAL") && profile?.status) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const nextChargeDate = new Date(profile?.next_charge_date);
      nextChargeDate.setHours(0, 0, 0, 0);
      const diffTime = nextChargeDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays > 1
        ? `${diffDays} dias restantes`
        : `${diffDays} dia restante`;
    }

    return null;
  }, [profile?.product_id, profile?.status, profile?.next_charge_date]);

  const menuItems = [
    {
      name: "Home",
      href: "/dashboard",
      icon: <Home size={20} className="text-gray" />,
    },
    {
      name: "Links Úteis",
      href: "#",
      onClick: () => window.open("https://phase-roquefort-ad1.notion.site/Links-teis-da-Zatten-10aa0260d4ea808a8d47cc2ae3e9f2e7", "_blank"),
      icon: <LinkIcon size={20} className="text-gray" />,
    },
    {
      name: "Meus Dados",
      href: "/dashboard/profile",
      icon: <CircleUserRound size={20} className="text-gray" />,
    },
    {
      name: "Minha Empresa",
      href: "/dashboard/company",
      icon: <Building size={20} className="text-gray" />,
      hide: (profile?.assistants_limit || 0) <= 1
    },
    {
      name: "Chaves de API",
      href: "/dashboard/api-keys",
      icon: <KeyRound size={20} className="text-gray" />,
    },
    {
      name: "Grupo do WhatsApp",
      href: "#",
      onClick: () => window.open("https://chat.whatsapp.com/IQRQ2kiOMUoAE44BiI72BQ", "_blank"),
      icon: <MessageCircle size={20} className="text-gray" />,
    },
    {
      name: "Sair",
      href: "#",
      icon: <LogOut size={20} className="text-gray" />,
      onClick: () => logout(),
    },
  ];

  const Logo = () => (
    <div className="flex items-center space-x-2">
      <Link to="/dashboard">
        <div className="flex items-center w-12 h-12 justify-center  mr-4 bg-primary rounded-md">
          <img className="w-8 h-8" src={LogoIcon} alt="" />
        </div>
      </Link>
    </div>
  );

  const SidebarContent = ({ showLogo = false }) => (
    <div className="flex flex-col h-full">
      <ScrollArea className="flex-grow">
        <div className="flex flex-col p-4 h-full">
          <div className="flex flex-row items-center mt-1 mb-8">
            {showLogo && (
              <div>
                <Logo />
              </div>
            )}

            <h1 className="text-xl leading-5 md:text-xl font-extrabold">
              Olá,{" "}
              <span className="text-primary">
                {session?.user.name.split(" ")[0]}
              </span>
            </h1>
          </div>

          <div className={`mb-8 space-y-2 ${!profile && "hidden"}`}>
            {profile?.product_id === "WHITE_LABEL" && (
              <div className="flex items-center bg-background border border-border text-green-800 px-3 py-2 rounded-md text-sm font-medium inline-block">
                <VerifiedIcon className="inline-block mr-1 h-5 w-5 text-green" />
                <p>Membro Fundador</p>
              </div>
            )}

            {!profile?.status && (
              <div className="flex items-center bg-background border border-border text-green-800 px-3 py-2 rounded-md text-sm font-medium inline-block">
                <OctagonAlert className="inline-block mr-1 h-5 w-5 text-red" />
                <p>Assinatura vencida</p>
              </div>
            )}

            {trialDaysRemaining !== null && (
              <div className="flex items-center bg-background border border-border text-green-800 px-3 py-2 rounded-md text-sm font-medium inline-block">
                <FlaskConical className="inline-block mr-1 h-5 w-5 text-yellow" />
                <p>{trialDaysRemaining}</p>
              </div>
            )}
          </div>

          {menuItems.map((item, index) => (
            <Link
              key={item.name}
              to={item.href}
              onClick={item.onClick}
              className={`px-4 mb-2 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200 hover:text-gray-900 rounded-md transition-colors hover:bg-background ${
                currentPath === item.href ? "bg-background" : ""
              } ${item.hide ? "hidden" : ""}`}
            >
              <div className="flex items-center">
                {item.icon}
                <p className="ml-2">{item.name}</p>
              </div>
            </Link>
          ))}
        </div>
      </ScrollArea>
    </div>
  );

  const ActionButtons = ({ fullWidth = false }) => (
    <div className={`flex ${fullWidth ? "flex-col space-y-2" : "space-x-2"}`}>
      {actions}
    </div>
  );

  return (
    <div className="flex h-screen flex-col md:flex-row">
      <header className="fixed top-0 left-0 right-0 z-40 flex h-16 items-center justify-between px-4 lg:hidden bg-card">
        <div className="flex items-center space-x-4">
          <Sheet open={sidebarOpen} onOpenChange={setSidebarOpen}>
            <SheetTrigger asChild>
              <Button variant="outlineIcon" size="icon">
                <PanelLeft className="h-6 w-6" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="w-[240px] sm:w-[300px] p-0">
              <SidebarContent showLogo={true} />
            </SheetContent>
          </Sheet>
        </div>
        <h1 className="text-md font-bold flex-1 flex justify-center items-center text-center">{title}</h1>
        <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
          {!!actions && (
            <DrawerTrigger asChild>
              <Button variant="outlineIcon" size="icon">
                <MoreVertical className="h-6 w-6" />
                <span className="sr-only">Open actions</span>
              </Button>
            </DrawerTrigger>
          )}
          <DrawerContent>
            <div className="p-4">
              <ActionButtons fullWidth />
            </div>
          </DrawerContent>
        </Drawer>
      </header>
      <aside className="hidden lg:block w-64 h-screen bg-card">
        <SidebarContent showLogo={true} />
      </aside>
      <main className="flex-1 overflow-auto z-0">
        <div>
          <div className="sticky top-0 z-50 border-border">
            <div className="md:flex md:justify-between md:items-center container mx-auto py-6 bg-background px-6">
              <h2 className="text-2xl font-bold hidden md:block">{title}</h2>
              <div className="hidden md:block">
                <ActionButtons />
              </div>
            </div>
          </div>
          <div className="container mx-auto px-6 py-8">{children}</div>
        </div>
      </main>
    </div>
  );
});

export default Sidebar;
