import { Alert, AlertDescription, AlertTitle } from "@/components/ui/Alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/AlertDialog";
import { Button } from "@/components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/Dialog";
import { Input } from "@/components/ui/Input";
import { Label } from "@/components/ui/Label";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/Table";
import { withAuth } from "@/contexts/authContext";
import { ApiKey } from "@/dtos/apiKey";
import api from "@/services/api";
import { copyToClipboard } from "@/utils/copyToClipboard";
import {
  CircleAlert,
  ClipboardCopy,
  FileText,
  Plus,
  Trash,
} from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import Sidebar, { SidebarRef } from "./components/Sidebar";

const ApiKeys: React.FC = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [error, setError] = useState(false);
  const [keys, setKeys] = useState<ApiKey[]>([]);

  const [newKeyName, setNewKeyName] = useState("");
  const [openNewKey, setOpenNewKey] = useState(false);
  const [openDeleteKey, setOpenDeleteKey] = useState(false);

  const sidebarRef = useRef<SidebarRef>(null);

  const loadApiKeys = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const { data } = await api.get("users/api-key");
      setKeys(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateNewKey = async () => {
    setOpenNewKey(false);
    try {
      await api.post("users/api-key", {
        name: newKeyName,
      });
      alert.success("Chave de API criada com sucesso!");
      loadApiKeys();
      setNewKeyName("");
    } catch (error) {
      alert.error(
        "Ocorreu um erro ao criar a chave de API. Tente novamente mais tarde."
      );
    }
  };

  const navigateAPIDocs = () => {
    window.open(
      "https://phase-roquefort-ad1.notion.site/Documenta-o-da-API-bcc100def9804fba8fe877a113b7000b?pvs=4",
      "_blank"
    );
  };

  const handleDeleteNewKey = async (key: string) => {
    setOpenDeleteKey(false);
    setLoadingDelete(true);
    try {
      await api.delete(`users/api-key/${key}`);
      alert.success("Chave de API deletada com sucesso!");
      loadApiKeys();
      setNewKeyName("");
    } catch (error) {
      alert.error(
        "Ocorreu um erro ao deletar a chave de API. Tente novamente mais tarde."
      );
    } finally {
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    loadApiKeys();
  }, [loadApiKeys]);

  return (
    <Sidebar
    ref={sidebarRef}
      title="Chaves de API"
      actions={
        <>
          <Button variant="outline" onClick={navigateAPIDocs}>
            Ver documentação
            <FileText size={18} strokeWidth={3} className="ml-2" />
          </Button>
          <Button onClick={() => {
            sidebarRef.current?.closeDrawer();
            setOpenNewKey(true)
          }}>
            Criar nova chave
            <Plus size={18} strokeWidth={3} className="ml-2" />
          </Button>
        </>
      }
    >
      <Alert variant="warning">
        <CircleAlert className="text-yellow" />
        <AlertTitle>Alerta de uso de chaves de API</AlertTitle>
        <AlertDescription>
          Recomendamos usar chaves de API baseadas em atendentes para um
          controle mais específico do uso e facil substituição
        </AlertDescription>

        <AlertDescription>
          Não compartilhe sua chave de API com outros, nem a exponha no
          navegador ou em outro código do lado do cliente.
        </AlertDescription>
      </Alert>

      <Dialog open={openNewKey} onOpenChange={setOpenNewKey}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Criar chave</DialogTitle>
            <DialogDescription>
              Escolha um nome para a sua chave, esse nome facilitará o
              reconhecimento da chave mais tarde
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <Label htmlFor="name">Nome</Label>
            <Input
              id="name"
              value={newKeyName}
              placeholder="chave cliente fulano"
              className="col-span-3"
              onChange={(e) => setNewKeyName(e.target.value)}
            />
          </div>
          <DialogFooter>
            <Button
              onClick={handleCreateNewKey}
              disabled={newKeyName.length < 3}
              type="submit"
            >
              Criar chave de API
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {error && (
        <div className="flex items-center flex-col justify-center mt-24">
          <h1 className="text-xl font-bold text-text text-center">
            Ops! Ocorreu um erro ao carregar as chaves de API. <br /> Tente
            novamente.
          </h1>
          <Button className="mt-4" variant="outline" onClick={loadApiKeys}>
            Tentar novamente
          </Button>
        </div>
      )}

      {!error && (
        <Table
          className={`${
            loadingDelete ? "opacity-50 pointer-events-none mt-8" : "mt-8"
          }`}
        >
          {keys.length === 0 && !loading && (
            <TableCaption className="mb-8">
              Você ainda não tem chaves de API. Crie uma e faça suas
              integrações.
            </TableCaption>
          )}
          {loading && (
            <TableCaption className="mb-8">
              Carregando chaves de API...
            </TableCaption>
          )}
          <TableHeader>
            <TableRow>
              <TableHead className="min-w-[200px]">Nome</TableHead>
              <TableHead className="min-w-[200px]">Chave de API</TableHead>
              <TableHead className="min-w-[200px] text-center">Ações</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {keys.map((key) => (
              <TableRow key={key.apiKey}>
                <TableCell>{key.name}</TableCell>
                <TableCell>{key.apiKey}</TableCell>
                <TableCell>
                  <div className="flex items-center justify-center gap-2">
                    <Button
                      variant="ghost"
                      onClick={() => {
                        copyToClipboard(key.apiKey);

                        alert.info(
                          "Chave de API copiada para a área de transferência"
                        );
                      }}
                    >
                      <ClipboardCopy size={16} className="text-text" />
                    </Button>

                    <AlertDialog
                      onOpenChange={setOpenDeleteKey}
                      open={openDeleteKey}
                    >
                      <AlertDialogTrigger>
                        <Button variant="ghost">
                          <Trash size={16} className="text-red" />
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Tem certeza que deseja excluir a chave{" "}
                            <span className="text-primary">{key.name}</span>?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            Essa ação não poderá ser desfeita. Isso irá exlcuir
                            permanentemente a chave de API e poderá comprometer
                            suas integrações.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel
                            onClick={() => handleDeleteNewKey(key.apiKey)}
                            className="text-red"
                          >
                            Excluir
                          </AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => setOpenDeleteKey(false)}
                          >
                            Cancelar
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Sidebar>
  );
};

export default withAuth(ApiKeys);
