export const formatPhone = (phone: string) => {
    if (phone.length === 13) {
        return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "$1 ($2) $3-$4");
    }

    return phone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "$1 ($2) $3-$4");
}

export const formatDate = (date: string, withTime = false) => {
    const formattedTime = new Date(date).toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

    return new Date(date).toLocaleDateString("pt-BR") + (withTime ? ` ${formattedTime}` : '');
}