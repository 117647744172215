import React, { useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import HalfWithForm from "../components/HalfWithForm";
import { Link } from "react-router-dom";
import { withoutAuth } from "../contexts/authContext";
import * as Yup from "yup";
import getValidationErrors from "../utils/getYupValidationErrors";
import api from "../services/api";
import { useAlert } from "react-alert";

interface FormProps {
  email: string;
}

const Schema = Yup.object().shape({
  email: Yup.string().required("Obrigatório").email("Email inválido"),
});

const ForgotPassword: React.FC = () => {
  const alert = useAlert();
  const [email, setEmail] = useState("");

  const [error, setError] = useState({} as FormProps);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError({} as FormProps);

    const data = {
      email,
    };

    try {
      await Schema.validate(data, {
        abortEarly: false,
      });

      await api.post("users/forgot-password", {
        email,
      });

      alert.success("Email enviado com sucesso");
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newError = getValidationErrors(err);
        setError(newError as FormProps);
      } else {
        alert.error('Erro ao enviar email. Tente novamente mais tarde');
      }
    }
    setLoading(false);
  };

  return (
    <HalfWithForm>
      <h1 className="text-center text-3xl my-2 font-extrabold">
        Recuperar senha
      </h1>
      <p className="text-center mb-16 text-xl mt-4">
        Para recuperar sua senha, preencha o email abaixo
      </p>

      <Input
        name="Email"
        onChange={setEmail}
        placeholder="Digite seu email"
        value={email}
        error={error.email}
      />
      <Button name={loading ? "Enviando..." : "Enviar"} onClick={handleSubmit} disabled={loading} />

      <Link
        to="/"
        className="text-center text-gray mt-16 underline decoration-solid"
      >
        Voltar ao login
      </Link>
    </HalfWithForm>
  );
};

export default withoutAuth(ForgotPassword);
