const firebaseConfig = {
    apiKey: 'AIzaSyCHf9RYK1bjEJQZiep-AySI6C2cF26dll0',
    authDomain: 'flashcards-28534.firebaseapp.com',
    projectId: 'flashcards-28534',
    storageBucket: 'flashcards-28534.appspot.com',
    messagingSenderId: '302714942952',
    appId: '1:302714942952:web:0cda612d0d4c2c8cc7205d',
    measurementId: 'G-YMEL778ZJS',
  };
  
  export default firebaseConfig;