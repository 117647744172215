import React from "react";
import { useAuth } from "../../../contexts/authContext";
import Sidebar from "../components/Sidebar";

const Profile: React.FC = () => {
  const { profile, session } = useAuth();

  return (
    <Sidebar title="Meus dados">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="gap-2 bg-card rounded-md p-8">
          <h1 className="text-md font-extrabold text-gray">Nome:</h1>
          <h1 className="text-2xl font-normal text-text">
            {session?.user.name}
          </h1>
        </div>

        <div className="gap-2 bg-card rounded-md p-8">
          <h1 className="text-md font-extrabold text-gray">E-mail:</h1>
          <h1 className="text-2xl font-normal text-text">
            {session?.user.email}
          </h1>
        </div>

        <div className="gap-2 bg-card rounded-md p-8">
          <h1 className="text-md font-extrabold text-gray">CPF:</h1>
          <h1 className="text-2xl font-normal text-text">
            {profile?.cpf || "-"}
          </h1>
        </div>

        <div className="gap-2 bg-card rounded-md p-8">
          <h1 className="text-md font-extrabold text-gray">Assinatura:</h1>
          <h1
            className={`text-2xl font-normal ${
              profile?.status ? "text-green" : "text-red"
            }`}
          >
            {profile?.status ? "Ativa" : "Inativa"}
          </h1>
        </div>
      </div>
    </Sidebar>
  );
};

export default Profile;
