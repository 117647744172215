import React, { useMemo, useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import HalfWithForm from "../components/HalfWithForm";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import getValidationErrors from "../utils/getYupValidationErrors";
import api from "../services/api";
import { withoutAuth } from "../contexts/authContext";
import { useAlert } from "react-alert";
import { navigateToWhatsapp } from "../utils/generic";

interface FormProps {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const Step1Schema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
  email: Yup.string().required("Obrigatório").email("Email inválido"),
});

const Step2Schema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Senha deve ter no mínimo 6 caracteres")
    .required("Senha obrigatória"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Senhas não conferem"
  ),
});

const Register: React.FC = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [error, setError] = useState({} as FormProps);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [formStep, setFormStep] = useState(1);

  const description = useMemo(() => {
    switch (formStep) {
      case 1:
        return (
          <span>
            Digite seu nome e email, assim podemos te conhecer melhor.
          </span>
        );
      case 2:
        return (
          <span>
            Hora de criar uma senha para acessar sua conta. Vamos lá 😉
          </span>
        );
      case 3:
        return (
          <span className="text-4xl">
            Sua conta foi criada com sucesso! <br />
            Aproveite o seu <b className="text-primary">acesso gratuito</b> e
            começe a automatizar seus atendimentos.
          </span>
        );
    }
  }, [formStep]);

  const title = useMemo(() => {
    switch (formStep) {
      case 3:
        return <span>Cadastro finalizado com sucesso 🎉</span>;
      default:
        return (
          <span>
            Criando uma conta agora você garante <br />{" "}
            <b className="text-primary">7 dias de acesso grátis</b>
          </span>
        );
    }
  }, [formStep]);

  const handleSubmitStep1 = async () => {
    setError({} as FormProps);

    const data = {
      name,
      email,
    };

    try {
      await Step1Schema.validate(data, {
        abortEarly: false,
      });

      setFormStep(2);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newError = getValidationErrors(err);
        setError(newError as FormProps);
      }
    }
  };

  const handleSubmitStep2 = async () => {
    setLoading(true);
    setError({} as FormProps);

    const data = {
      password,
      confirmPassword,
    };

    try {
      await Step2Schema.validate(data, {
        abortEarly: false,
      });

      await api.post("users/trial-register", {
        name,
        email,
        password,
      });

      setFormStep(3);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newError = getValidationErrors(err);
        setError(newError as FormProps);
      } else {
        alert.error("Erro ao cadastrar usuário. Tente novamente mais tarde");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <HalfWithForm>
      <div className="flex flex-row mb-16 gap-4">
        <div
          className={`flex-1 ${
            formStep >= 1 ? "bg-primary" : "bg-gray"
          } h-1 rounded-full transition-all`}
        ></div>
        <div
          className={`flex-1 ${
            formStep >= 2 ? "bg-primary" : "bg-gray"
          } h-1 rounded-full transition-all`}
        ></div>
        <div
          className={`flex-1 ${
            formStep >= 3 ? "bg-primary" : "bg-gray"
          } h-1 rounded-full transition-all`}
        ></div>
      </div>

      <h1 className="text-center text-3xl my-2 font-extrabold">{title}</h1>
      <p className="text-center mb-16 text-xl mt-4">{description}</p>

      {formStep === 1 && (
        <>
          <Input
            name="Nome"
            onChange={setName}
            placeholder="Digite seu nome"
            value={name}
            error={error.name}
          />
          <Input
            name="Email"
            onChange={setEmail}
            placeholder="Digite seu email"
            value={email}
            error={error.email}
          />

          <Button name="Continuar" onClick={handleSubmitStep1} />
        </>
      )}

      {formStep === 2 && (
        <>
          <Input
            name="Senha"
            onChange={setPassword}
            placeholder="Digite sua senha"
            value={password}
            isPassword
            error={error.password}
          />
          <Input
            name="Confirme sua senha"
            onChange={setConfirmPassword}
            placeholder="Digite sua senha novamente"
            value={confirmPassword}
            isPassword
            error={error.confirmPassword}
          />

          <Button
            name={loading ? "Carregando..." : "Finalizar"}
            disabled={loading}
            onClick={handleSubmitStep2}
          />

          <p className="text-xs mt-8 text-center">
            Ao criar sua conta, você concorda com os nossos{" "}
            <a className="text-primary" href="http://zatten.com/terms" target="_blank" rel="noreferrer">
              Termos de uso
            </a>
          </p>
          
          <Button name="👈 Voltar" ghost onClick={() => setFormStep(1)} />
        </>
      )}

      {formStep === 3 && (
        <>
          <Button name="Fazer login" onClick={() => navigate("/login")} />
          <Button name="Entrar no grupo do Whatsapp" success onClick={navigateToWhatsapp} />
        </>
      )}

      {formStep !== 3 && (
        <p className="text-center mt-4">
          Já possui uma conta?{" "}
          <Link to="/login">
            <span className="text-primary font-bold">Entrar</span>
          </Link>
        </p>
      )}
    </HalfWithForm>
  );
};

export default withoutAuth(Register);
