import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/AlertDialog";
import { Button } from "@/components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/Dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/DropdownMenu";
import { copyToClipboard } from "@/utils/copyToClipboard";
import {
  ClipboardCopy,
  Contact,
  EllipsisVertical,
  Power,
  PowerOff,
  Settings,
  SquareAsterisk,
  Trash,
} from "lucide-react";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/authContext";
import { UserPhone } from "../../../../dtos/userPhone";
import api from "../../../../services/api";
import { formatPhone } from "../../../../utils/format";
import CreateOrUpdateClientAccess from "./CreateOrUpdateClientAccess";

interface NumberItemProps {
  phone: UserPhone;
  index: number;
  loadPhones: () => void;
}

const NumberItem = ({ loadPhones, phone, index }: NumberItemProps) => {
  const { profile } = useAuth();
  const navigate = useNavigate();

  const assistantsLimit = profile?.assistants_limit || 1;

  const blocked = profile && !profile.status;
  const alert = useAlert();

  const [connectAlert, setConnectAlert] = useState(false);
  const [removeAlert, setRemoveAlert] = useState(false);
  const [accessModal, setAccessModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState({
    code: "",
    qr: "",
  });

  const disconnect = async () => {
    await api.delete(`/disconnect-whatsapp/${phone._id}`);
    loadPhones();
  };

  const connect = async () => {
    setLoading(true);
    setConnectAlert(true);

    try {
      const { data } = await api.post<{ code: string; qr: string }>(
        `/connect-whatsapp/${phone._id}`
      );

      setCode(data);
    } catch (error) {
      alert.error("Erro ao conectar. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  };

  const removeNumber = async () => {
    setRemoveAlert(false);
    setLoading(true);
    try {
      await api.delete(`/user-phone/${phone._id}`);
      loadPhones();
    } catch (error) {
      alert.error("Erro ao remover. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  };

  const onCloseConnectModal = () => {
    setConnectAlert(false);
    loadPhones();
  };

  const ConfirmDeleteNumber = () => {
    return (
      <AlertDialog open={removeAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Tem certeza que deseja remover o atendente?
            </AlertDialogTitle>
            <AlertDialogDescription>
              Essa ação não poderá ser desfeita. Isso irá exlcuir
              permanentemente o atendente, os contatos e o acesso.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={removeNumber} className="text-red">
              Excluir
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => setRemoveAlert(false)}>
              Cancelar
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };

  const ConnectModal = () => {
    return (
      <Dialog
        open={connectAlert}
        onOpenChange={(open) => {
          setConnectAlert(open);
          if (!open) {
            loadPhones();
          }
        }}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Conectar atendente</DialogTitle>
          </DialogHeader>
          <div>
            {loading && <p className="text-center text-3xl">Carregando...</p>}

            {!loading && code.code && (
              <>
                <p className="text-center text-md  mt-4">Código de acesso:</p>
                <p className="text-center text-primary font-extrabold text-xl tracking-large mt-2">
                  {code.code.replace(/^(.{4})/, "$1-")}
                </p>
              </>
            )}

            {!loading && code.qr && (
              <>
                <p className="text-center text-md mt-8">QR code:</p>
                <img
                  className="w-60 h-60 object-contain mx-auto mt-4 rounded-lg bg-white p-0"
                  src={code.qr}
                  alt="QR Code"
                />
              </>
            )}
          </div>

          <DialogFooter className="mt-6">
            <Button
              disabled={loading}
              className="flex flex-1 bg-background"
              variant="ghost"
              onClick={connect}
            >
              Gerar novo código
            </Button>
            <Button
              className="flex flex-1"
              onClick={onCloseConnectModal}
              disabled={loading}
            >
              Já conectei
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div
      className={`flex items-center space-x-4 rounded-md bg-card border border-border p-4 ${
        blocked ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      <div className="flex-1 space-y-4">
        <div className="flex items-center">
          <span className="text-gray text-sm font-bold mr-4">#{index + 1}</span>
          <p className="text-lg font-medium leading-none">
            {phone?.clientAccess?.name || formatPhone(phone.phone)}
          </p>
          <div
            className={`w-3 h-3 rounded-full ml-auto ${
              phone.isConnected ? "bg-green" : "bg-red"
            }`}
          />
        </div>
        <button
          className="text-gray flex items-center justify-between"
          onClick={() => {
            copyToClipboard(phone._id);
            alert.info("attendantId copiado para a área de transferência");
          }}
        >
          <p className="text-xs line-clamp-1 text-left">{phone._id}</p>
          <ClipboardCopy size={14} className="ml-2" />
        </button>

        <div className="flex items-center gap-4 justify-between">
          {!phone.isConnected && (
            <Button className="flex flex-1" onClick={connect}>
              Conectar atendente
              <Power size={16} className="ml-2" />
            </Button>
          )}
          {phone.isConnected && (
            <Button className="flex flex-1 bg-red" onClick={disconnect}>
              Desconectar
              <PowerOff size={16} className="ml-2" />
            </Button>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="outlineIcon" size="icon">
                <EllipsisVertical className="h-6 w-6" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[250px]">
              <DropdownMenuItem
                onClick={() =>
                  navigate(`/dashboard/attendant`, {
                    state: {
                      phone,
                      isConnected: phone.isConnected,
                    },
                  })
                }
              >
                {" "}
                <Settings size={16} /> Editar atendente{" "}
              </DropdownMenuItem>
              {!phone.isConnected && (
                <>
                  <DropdownMenuItem onClick={() => setRemoveAlert(true)}>
                    <Trash size={16} /> Excluir atendente
                  </DropdownMenuItem>
                </>
              )}

              {assistantsLimit > 1 && (
                <DropdownMenuItem onClick={() => setAccessModal(true)}>
                  <SquareAsterisk size={16} /> Gerenciar acesso
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                onClick={() =>
                  navigate(`/dashboard/leads`, {
                    state: { phoneId: phone._id, phone: phone.phone },
                  })
                }
              >
                <Contact size={16} /> Ver contatos
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <ConfirmDeleteNumber />
      <ConnectModal />
      <CreateOrUpdateClientAccess
        currentPhone={phone}
        isOpen={accessModal}
        setState={setAccessModal}
        onSubmit={loadPhones}
      />
    </div>
  );
};

export default NumberItem;
