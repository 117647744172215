import { Alert, AlertDescription, AlertTitle } from "@/components/ui/Alert";
import { Button } from "@/components/ui/Button";
import { OctagonAlert, Plus } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth, withAuth } from "../../../contexts/authContext";
import { UserPhone, UserPhones } from "../../../dtos/userPhone";
import api from "../../../services/api";
import Sidebar from "../components/Sidebar";
import NumberItem from "./components/NumberItem";
import { useNavigate } from "react-router-dom";

const WhatsAppConfiguration: React.FC = () => {
  const { profile } = useAuth();
  const navigate = useNavigate();

  const blocked = profile && !profile.status;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [phones, setPhones] = useState<UserPhone[]>([]);

  const blockButton = useMemo(() => {
    if (blocked) return true;

    if (loading) return true;

    if (!profile?.status) return true;

    return phones.length >= (profile?.assistants_limit || 1);
  }, [blocked, loading, phones.length, profile?.assistants_limit, profile?.status]);

  const loadPhones = useCallback(async () => {
    setError(false);
    setLoading(true);
    setPhones([]);

    try {
      const { data } = await api.get<UserPhones>("/user-phone");

      if (data.phones.length) {
        setPhones(data.phones);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const navigateToPricing = () => {
    window.open("http://zatten.com/pricing", "_blank");
  };

  const navigateToAttendant = () => {
    navigate(`/dashboard/attendant`);
  };

  useEffect(() => {
    loadPhones();
  }, [loadPhones]);

  return (
    <Sidebar
      title={`Meus atendentes ${profile ? `(${phones.length}/${profile?.assistants_limit})` : ''}`}
      actions={
        <Button disabled={blockButton} onClick={navigateToAttendant}>
          <Plus strokeWidth={3} size={20} className="mr-2" /> Novo atendente
        </Button>
      }
    >
      {profile && !profile.status && (
        <Alert
          variant="default"
          className="mb-5 border-red border flex items-center justify-between"
        >
          <OctagonAlert className="text-red" />
          <div>
            <AlertTitle>
              {profile.product_id.includes("TRIAL")
                ? "Período de teste expirado"
                : "Não identificamos o pagamento do seu plano"}
            </AlertTitle>
            <AlertDescription>
              Para continuar utilizando o sistema, renove sua assinatura agora
              mesmo
            </AlertDescription>
          </div>
          <div>
            <Button onClick={navigateToPricing}>Renovar assinatura</Button>
          </div>
        </Alert>
      )}

      {(error || !phones.length) && !loading && (
        <p className="text-2xl mt-20 text-gray text-center">
          Você ainda não possui um atendente cadastrado
        </p>
      )}

      {loading && (
        <p className="text-2xl mt-20 text-gray text-center">Carregando...</p>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {phones.map((phone, index) => (
          <NumberItem
            index={index}
            loadPhones={loadPhones}
            key={phone._id}
            phone={phone}
          />
        ))}
      </div>
    </Sidebar>
  );
};

export default withAuth(WhatsAppConfiguration);
