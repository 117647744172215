import React from "react";

type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

type HTMLInput = Without<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange"
>;

interface InputProps extends HTMLInput {
  name?: string;
  description?: React.ReactNode;
  description2?: React.ReactNode;
  description3?: React.ReactNode;
  placeholder?: string;
  value: string;
  error?: string;
  isPassword?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  withoutSpacing?: boolean;
}

const Input = ({
  onChange,
  value,
  placeholder,
  name,
  description,
  description2,
  description3,
  error,
  isPassword,
  disabled,
  withoutSpacing,
  ...rest
}: InputProps) => {
  return (
    <div className={`${withoutSpacing ? "" : "mb-4"} w-full`}>
      <p className="mb-2 text-text font-medium">
        {name && <span>{name}</span>}
        {description && (
          <>
            <br />
            <span className="text-xs font-normal">{description}</span>
          </>
        )}
        {description2 && (
          <>
            <br />
            <span className="text-xs font-normal">{description2}</span>
          </>
        )}
        {description3 && (
          <>
            <br />
            <span className="text-xs font-normal">{description3}</span>
          </>
        )}
      </p>
      <input
        {...rest}
        disabled={disabled}
        type={isPassword ? "password" : "text"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`w-full bg-background rounded-md text-text p-4 border-2 border-gray focus:border-primary focus:outline-none placeholder:text-gray ${
          disabled ? "opacity-50" : ""
        }`}
        placeholder={placeholder}
      />
      {!!error && <p className="text-red text-xs mt-1">{error}</p>}
    </div>
  );
};

export default Input;
