import React, { useMemo } from "react";

interface ButtonProps {
  name?: string;
  small?: boolean;
  ghost?: boolean;
  outline?: boolean;
  withoutSpacing?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  icon?: JSX.Element;
  success?: boolean;
  error?: boolean;
}

const Button = ({
  name,
  onClick,
  small,
  ghost,
  outline,
  withoutSpacing,
  disabled,
  icon,
  success,
  error
}: ButtonProps) => {
  const borderColor = useMemo(() => {
    if (success) return "border-green";
    if (error) return "border-red";
    return "border-text";
  }, [success, error])

  const bgColor = useMemo(() => {
    if (success) return "bg-green";
    if (error) return "bg-red";
    return "bg-primary";
  }, [error, success])

  return (
    <button
      disabled={disabled}
      className={`
      flex items-center gap-2 justify-center
      ${disabled ? "opacity-50" : "hover:scale-105 transition-all"}
      ${
        ghost || outline
          ? "bg-background text-text text-sm"
          : `${bgColor} text-white`
      } rounded-md p-4 ${small ? "text-sm" : "flex-1 max-h-14"}  ${
        withoutSpacing ? "" : "mt-8"
      }  font-bold ${outline ? `border ${borderColor}` : ""}`}
      onClick={onClick}
    >
      {name}
      {icon}
    </button>
  );
};

export default Button;
