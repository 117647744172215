export function copyToClipboard(text: string) {
    // Cria um elemento de texto temporário
    const tempInput = document.createElement('input');
    tempInput.value = text;
  
    // Adiciona o elemento ao corpo do documento
    document.body.appendChild(tempInput);
  
    // Seleciona o texto no campo de entrada
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis
  
    // Executa o comando de cópia
    document.execCommand('copy');
  
    // Remove o elemento temporário
    document.body.removeChild(tempInput);
  }
  