import React, { useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import HalfWithForm from "../components/HalfWithForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import { withoutAuth } from "../contexts/authContext";
import * as Yup from "yup";
import getValidationErrors from "../utils/getYupValidationErrors";
import api from "../services/api";
import { useAlert } from "react-alert";

interface FormProps {
  password: string;
  confirmPassword: string;
}

const Schema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Senha deve ter no mínimo 6 caracteres")
    .required("Senha obrigatória"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Senhas não conferem"
  ),
});

const ForgotPassword: React.FC = () => {
  const { token } = useParams();
  
  const navigate = useNavigate();
  const alert = useAlert();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState({} as FormProps);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError({} as FormProps);

    const data = {
      password,
      confirmPassword,
    };

    try {
      await Schema.validate(data, {
        abortEarly: false,
      });

      await api.patch(`users/reset-password/${token}`, {
        password,
      });

      alert.success("Senha alterada com sucesso");
      navigate("/login");
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newError = getValidationErrors(err);
        setError(newError as FormProps);
      } else {
        alert.error("Erro ao alterar a senha. Tente novamente mais tarde");
      }
    }
    setLoading(false);
  };

  return (
    <HalfWithForm>
      <h1 className="text-center text-3xl my-2 font-extrabold">
        Troca de senha
      </h1>
      <p className="text-center mb-16 text-xl mt-4">
        Digite sua nova senha
      </p>

      <Input
        name="Senha"
        onChange={setPassword}
        placeholder="Digite sua senha"
        value={password}
        isPassword
        error={error.password}
      />
      <Input
        name="Confirme sua senha"
        onChange={setConfirmPassword}
        placeholder="Digite sua senha novamente"
        value={confirmPassword}
        isPassword
        error={error.confirmPassword}
      />
      <Button
        name={loading ? "Carregando..." : "Confirmar"}
        onClick={handleSubmit}
        disabled={loading}
      />

      <Link
        to="/"
        className="text-center text-gray mt-16 underline decoration-solid"
      >
        Voltar ao login
      </Link>
    </HalfWithForm>
  );
};

export default withoutAuth(ForgotPassword);
