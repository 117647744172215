import axios from "axios";
import { getSavedSession, LOCAL_STORAGE_SESSION_KEY } from "../utils/verifyToken";

const api = axios.create({
  baseURL: "https://zatten.up.railway.app/",
});

api.interceptors.request.use((config) => {
  const savedSession = getSavedSession();

  if (savedSession) {
    config.headers.Authorization = `Bearer ${savedSession.token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/";
      localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
    }

    return Promise.reject(error);
  }
)

export default api