import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  ReactNode,
} from "react";
import api from "../services/api";
import { Navigate, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import { getSavedSession, LOCAL_STORAGE_SESSION_KEY, saveSession } from "../utils/verifyToken";

export interface SessionResponse {
  token: string;
  user: {
    name: string;
    email: string;
  };
}

export interface ProfileResponse {
  product_id: "TRIAL" | "INDIVIDUAL" | "PRO" | "TRIAL_EXPIRED" | "CANCELED" | "WHITE_LABEL";
  status: boolean;
  cpf: string;
  next_charge_date: string;
  assistants_limit: number;
  company?: {
    color: {
      primary: string;
      secondary: string;
    };
    nickname: string;
    companyName: string;
    contact: string;
    imageUrl: string;
  }
}

interface LoginProps {
  email: string;
  password: string;
}

interface AuthContextData {
  session?: SessionResponse;
  profile?: ProfileResponse;
  login: (data: LoginProps) => Promise<void>;
  logout: () => Promise<void>;
  getProfileData: () => Promise<void>;
}

const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: { children: ReactNode }) {
  const alert = useAlert();

  const [session, setSession] = useState<SessionResponse>();
  const [profile, setProfile] = useState<ProfileResponse>();

  const getProfileData = useCallback(async () => {
    const { data } = await api.get(`users/profile`);
    setProfile(data);
  }, []);

  const login = useCallback(
    async (data: LoginProps) => {
      try {
        const response = await api.post<SessionResponse>("sessions", data);
        saveSession(response.data);
        setSession(response.data);
        getProfileData();
      } catch (err) {
        alert.error("Email ou senha inválidos");
      }
    },
    [alert, getProfileData]
  );

  const logout = useCallback(async () => {
    setSession(undefined);
    localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
  }, []);

  useEffect(() => {
    const session = getSavedSession();

    if (session?.token) {
      setSession(session);
      getProfileData();
    }
  }, [getProfileData]);

  const value = useMemo(
    () => ({
      session,
      login,
      logout,
      profile,
      getProfileData
    }),
    [login, logout, session, profile, getProfileData]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within as Authprovider");
  }

  return context;
}

const AVAILABLE_ROUTES = [
  "/dashboard",
  "/dashboard/support",
]

export function withAuth(Component: React.ComponentType) {
  return (props: any) => {
    const { session, profile } = useAuth();
    const { pathname } = useLocation();

    if (profile && !profile.status && !AVAILABLE_ROUTES.includes(pathname)) {
      return <Navigate to="/dashboard" replace />;
    }

    if (!session) {
      return <Navigate to="/login" replace />;
    }

    return <Component {...props} />;
  };
}

export function withoutAuth(Component: React.ComponentType) {
  return (props: any) => {
    const { session } = useAuth();

    if (session) {
      return <Navigate to="/dashboard" replace />;
    }

    return <Component {...props} />;
  };
}
