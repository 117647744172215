import React, { useMemo, useState } from "react";
import HalfWithForm from "../components/HalfWithForm";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../components/Input";
import Button from "../components/Button";
import * as Yup from "yup";
import getValidationErrors from "../utils/getYupValidationErrors";
import api from "../services/api";
import isValidCPF from "../utils/isValidCpf";
import { useAlert } from "react-alert";
import { withoutAuth } from "../contexts/authContext";

interface FormProps {
  email: string;
  cpf: string;
  password: string;
  confirmPassword: string;
}

const Step1Schema = Yup.object().shape({
  email: Yup.string().required("Obrigatório").email("Email inválido"),
  cpf: Yup.string()
    .required("Obrigatório")
    .test("cpf", "CPF inválido", (value: string) => {
      return isValidCPF(value);
    }),
});

const Step2Schema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Senha deve ter no mínimo 6 caracteres')
    .required('Senha obrigatória'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password')],
    'Senhas não conferem',
  ),
})

const CompleteRegister: React.FC = () => {
  const alert = useAlert();
  const { id } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState({} as FormProps);
  const [loading, setLoading] = useState(false);

  const [formStep, setFormStep] = useState(1);

  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");

  const [name, setName] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const description = useMemo(() => {
    switch (formStep) {
      case 1:
        return (
          <span>
            🚨🚨🚨🚨 <br /> Preencha os campos abaixo com os dados usados no
            momento da assinatura, <b>precisamos te identificar</b>.
          </span>
        );
      case 2:
        return (
          <span>
            Agora vamos definir a senha que você vai usar para acessar a
            plataforma
          </span>
        );
      case 3:
        return (
          <span className="text-4xl">
            Tudo certo para começar utilizar o{" "}
            <b className="text-primary">zatten</b> e configurar sua conta.
            Desejamos sucesso na sua jornada 🚀
          </span>
        );
    }
  }, [formStep]);

  const title = useMemo(() => {
    switch (formStep) {
      case 3:
        return "Cadastro finalizado com sucesso 🎉";
      case 2:
        return `Olá ${name}! Essa é a última etapa`;
      default:
        return "Vamos finalizar o seu cadastro 😄";
    }
  }, [formStep, name]);

  const handleSubmitStep1 = async () => {
    setLoading(true);
    setError({} as FormProps);

    const data = {
      email,
      cpf,
    };

    try {
      await Step1Schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.get<{ name: string }>(
        `users/pre-register/confirm-user-data/${id}`,
        {
          params: {
            email,
            cpf,
          },
        }
      );

      setName(response.data.name);

      setFormStep(2);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newError = getValidationErrors(err);
        setError(newError as FormProps);
      } else {
        alert.error("Email ou CPF inválidos");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitStep2 = async () => {
    setLoading(true);
    setError({} as FormProps);

    const data = {
      password,
      confirmPassword,
    };
    
    try {
      await Step2Schema.validate(data, {
        abortEarly: false,
      });

      await api.put<{ name: string }>(
        `users/complete-register/${id}`,
        {
          password,
        }
      );

      setFormStep(3);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newError = getValidationErrors(err);
        setError(newError as FormProps);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <HalfWithForm>
      <div className="flex flex-row mb-16 gap-4">
        <div
          className={`flex-1 ${
            formStep >= 1 ? "bg-primary" : "bg-gray"
          } h-1 rounded-full transition-all`}
        ></div>
        <div
          className={`flex-1 ${
            formStep >= 2 ? "bg-primary" : "bg-gray"
          } h-1 rounded-full transition-all`}
        ></div>
        <div
          className={`flex-1 ${
            formStep >= 3 ? "bg-primary" : "bg-gray"
          } h-1 rounded-full transition-all`}
        ></div>
      </div>

      <h1 className="text-center text-3xl my-2 font-extrabold">{title}</h1>

      <p className="text-center mb-16 text-xl mt-4">{description}</p>

      {formStep === 1 && (
        <>
          <Input
            name="Email"
            onChange={setEmail}
            placeholder="Digite seu email"
            value={email}
            error={error.email}
          />
          <Input
            name="CPF"
            onChange={setCpf}
            placeholder="Digite seu cpf"
            value={cpf}
            error={error.cpf}
          />
          <Button name={loading ? "Carregando..." : "Continuar"} disabled={loading} onClick={handleSubmitStep1} />
        </>
      )}

      {formStep === 2 && (
        <>
          <Input
            name="Senha"
            onChange={setPassword}
            placeholder="Digite sua senha"
            value={password}
            isPassword
            error={error.password}
          />
          <Input
            name="Confirme sua senha"
            onChange={setConfirmPassword}
            placeholder="Digite sua senha novamente"
            value={confirmPassword}
            isPassword
            error={error.confirmPassword}
          />
          <Button name={loading ? "Carregando..." : "Finalizar"} disabled={loading} onClick={handleSubmitStep2} />

          <p className="text-xs mt-8 text-center">
            Ao criar sua conta, você concorda com os nossos{" "}
            <a className="text-primary" href="http://zatten.com/terms" target="_blank" rel="noreferrer">
              Termos de uso
            </a>
          </p>
        </>
      )}

      {formStep === 3 && (
        <>
          <Button name="Fazer login" onClick={() => navigate("/login")} />
        </>
      )}
    </HalfWithForm>
  );
};

export default withoutAuth(CompleteRegister);
