import { initializeApp } from "firebase/app";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { Image, Loader2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useAuth, withAuth } from "../../contexts/authContext";

import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/Input";
import { Label } from "@/components/ui/Label";
import { useAlert } from "react-alert";
import { BlockPicker } from "react-color";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import firebaseConfig from "../../config/firebase";
import api from "../../services/api";
import getValidationErrors from "../../utils/getYupValidationErrors";
import Sidebar from "./components/Sidebar";

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

interface FormProps {
  primaryColor: string;
  secondaryColor: string;
  nickname: string;
  companyName: string;
  contact: string;
  imageUrl: string;
}

const Schema = Yup.object().shape({
  primaryColor: Yup.string().required("Obrigatório"),
  secondaryColor: Yup.string().required("Obrigatório"),
  nickname: Yup.string().required("Obrigatório"),
  companyName: Yup.string().required("Obrigatório"),
  contact: Yup.string()
    .required("Obrigatório")
    .test("is-valid-phone", "Número inválido", (value) => {
      if (value.length < 12 || value.length > 13) {
        return false;
      }

      return true;
    }),
  imageUrl: Yup.string().url("URL inválida").required("Obrigatório"),
});

const Company: React.FC = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { profile, getProfileData, session } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({} as FormProps);

  const assistantsLimit = profile?.assistants_limit || 1;

  const [primaryColor, setPrimaryColor] = useState(
    profile?.company?.color?.primary || "#6D6AFF"
  );
  const [secondaryColor, setSecondaryColor] = useState(
    profile?.company?.color?.secondary || "#FFF"
  );
  const [nickname, setNickname] = useState(profile?.company?.nickname || "");
  const [companyName, setCompanyName] = useState(
    profile?.company?.companyName || ""
  );
  const [contact, setContact] = useState(profile?.company?.contact || "55");
  const [imageUrl, setImageUrl] = useState(profile?.company?.imageUrl || "");

  const [progress, setProgress] = useState(0);
  const [loadingImage, setLoadingImage] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError({} as FormProps);
    try {
      await Schema.validate(
        {
          primaryColor,
          secondaryColor,
          nickname,
          companyName,
          contact,
          imageUrl,
        },
        {
          abortEarly: false,
        }
      );

      const data = {
        color: {
          primary: primaryColor,
          secondary: secondaryColor,
        },
        nickname,
        companyName,
        contact,
        imageUrl,
      };

      if (profile?.company) {
        await api.patch("users/company", data);
      } else {
        await api.post("users/company", data);
      }

      await getProfileData();

      alert.success("Dados da empresa atualizados com sucesso");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const newError = getValidationErrors(error) as FormProps;
        setError(newError as FormProps);
      } else {
        alert.error("Erro ao atualizar dados da empresa");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let file;
    if (e.type === "drop") {
      file = e.dataTransfer.files[0]; // Arquivo solto
    } else {
      file = e.target.files[0]; // Arquivo selecionado pelo input
    }

    if (file && session?.user) {
      setLoadingImage(true);
      const storageRef = ref(storage, `user/${session?.user.email}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          alert.error("Erro ao fazer upload da imagem");
          setLoadingImage(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (profile?.company) {
              alert.success("Imagem atualizada com sucesso");
            }
            setImageUrl(downloadURL);
            setLoadingImage(false);
          });
        }
      );
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Desativa o drag quando o arquivo sai da área de drop
  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (assistantsLimit <= 1) {
      navigate("/dashboard");
    }
  }, [assistantsLimit, navigate]);

  return (
    <Sidebar title="Minha empresa / White Label">
      <div className="space-y-4">
        <div className="space-y-1">
          <Label>Nome da empresa</Label>
          <Input
            placeholder="Digite o nome da empresa"
            onChange={(e) => setCompanyName(e.target.value)}
            value={companyName}
            error={error.companyName}
          />
        </div>

        <div className="space-y-1">
          <Label>Subdomínio</Label>
          <p className="text-xs text-gray">
            Vamos te dar um domínio com o nome da sua empresa
          </p>
          <p className="text-xs text-gray">
            Seu domínio vai ser: {nickname ? `${nickname}.botcontrole.com` : ""}
          </p>
          <Input
            placeholder="Digite o subdomínio"
            onChange={(e) =>
              setNickname(
                e.target.value.toLocaleLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")
              )
            }
            value={nickname}
            error={error.nickname}
          />
        </div>

        <div className="space-y-1">
          <Label>Contato</Label>
          <p className="text-xs text-gray">
            Telefone para contato com a sua empresa
          </p>
          <p className="text-xs text-gray">
            Digite o código do país (Ex: 55 para Brasil)
          </p>
          <p className="text-xs text-gray">
            Digite o DDD da sua região (Ex: 11 para São Paulo)
          </p>
          <Input
            placeholder="Digite o contato"
            onChange={(e) => setContact(e.target.value.replace(/\D/g, ""))}
            value={contact}
            error={error.contact}
            maxLength={13}
          />
        </div>

        <div className="flex flex-row gap-4">
          <div className="w-full">
            <Label>Cor primária</Label>
            <BlockPicker
              color={primaryColor}
              onChange={(color) => setPrimaryColor(color.hex)}
              triangle="hide"
              className="mb-4"
              width="100%"
              styles={{
                default: {
                  head: {
                    height: "60px",
                  },
                },
              }}
            />
            {!!error.primaryColor && (
              <p className="text-red text-xs mt-1">{error.primaryColor}</p>
            )}
          </div>
          <div className="w-full">
            <Label>Cor secundária</Label>
            <BlockPicker
              color={secondaryColor}
              onChange={(color) => setSecondaryColor(color.hex)}
              triangle="hide"
              className="mb-4"
              width="100%"
              styles={{
                default: {
                  head: {
                    height: "60px",
                  },
                },
              }}
            />
            {!!error.secondaryColor && (
              <p className="text-red text-xs mt-1">{error.secondaryColor}</p>
            )}
          </div>
        </div>
      </div>

      <div className="space-y-1 mt-2">
        <Label>Logo da empresa</Label>
        <div className="flex items-center justify-center w-full">
          <label
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleImageChange}
            htmlFor="file-upload"
            className="p-2 pt-4 hover:border-primary flex flex-col items-center justify-center w-full border-2 border-dashed rounded-lg cursor-pointer"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              {imageUrl ? (
                <img
                  className="max-w-[200px] object-contain rounded-md mb-2"
                  src={imageUrl}
                  alt="Imagem da empresa"
                />
              ) : (
                <Image size={48} strokeWidth={1} className="mb-2" />
              )}

              <p className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Clique para enviar</span> ou
                arraste e solte
              </p>
              <p className="text-xs text-gray-500">PNG, JPG, até 10MB</p>
            </div>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleImageChange}
            />
            <div
              className={`h-2 w-full bg-gray rounded-full transition-all duration-300 ${
                progress > 0 && loadingImage ? "opacity-100" : "opacity-0"
              }`}
            >
              <div
                className="h-full bg-primary rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </label>
        </div>
        {!!error.imageUrl && (
          <p className="text-red text-xs mt-1">{error.imageUrl}</p>
        )}
      </div>

      <Button
        className="w-full mt-8"
        disabled={loading || loadingImage}
        size="lg"
        onClick={handleSubmit}
      >
        {loading && (
          <Loader2 className="mr-2 animate-spin" size={18} strokeWidth={3} />
        )}
        Salvar alterações
      </Button>
    </Sidebar>
  );
};

export default withAuth(Company);
