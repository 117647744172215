import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Location, useLocation } from "react-router-dom";
import api from "../../services/api";
import { Lead } from "../../dtos/leads";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/Table";
import { ListRestart, Loader2, MessageCircle, Trash } from "lucide-react";
import { formatPhone, formatDate } from "../../utils/format";
import { useAlert } from "react-alert";
import { Switch } from "@/components/ui/Switch";
import Sidebar, { SidebarRef } from "../Dashboard/components/Sidebar";
import { Button } from "@/components/ui/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/Select";
import { Input } from "@/components/ui/Input";

interface NavigationProps extends Location {
  state: {
    phoneId: string;
    phone: string;
  };
}

const Leads: React.FC = () => {
  const { state } = useLocation() as NavigationProps;
  const alert = useAlert();
  const [filter, setFilter] = useState("1");
  const [search, setSearch] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingToggleBotState, setLoadingToggleBotState] = useState(false);
  const [loadingDeleteLead, setLoadingDeleteLead] = useState(false);

  const sidebarRef = useRef<SidebarRef>(null);

  const [leads, setLeads] = useState<Lead[]>([]);

  const loadLeads = useCallback(
    async (shouldLoad = true) => {
      setError(false);

      if (shouldLoad) {
        setLoading(true);
        setLeads([]);
      }

      const params = filter === "all" ? {} : { daysAgo: filter };

      try {
        const { data } = await api.get<Lead[]>(`/leads/${state.phoneId}`, {
          params,
        });
        setLeads(data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [state.phoneId, filter]
  );

  const navigateToConversation = (phone: string) => {
    window.open(`https://api.whatsapp.com/send/?phone=${phone}`, "_blank");
  };

  const cleanAllThreads = async () => {
    setLoading(true);
    try {
      await api.delete(`/leads/clean-all-threads/${state.phoneId}`);
      alert.success("Threads limpas com sucesso!");
      loadLeads();
      sidebarRef.current?.closeDrawer();
    } catch (error) {
      alert.error("Erro ao limpar threads. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  };

  const deleteLead = async (leadId: string) => {
    setLoadingDeleteLead(true);
    try {
      await api.delete(`/leads/${leadId}`);
      alert.success("Contato deletado com sucesso!");
      loadLeads(false);
    } catch (error) {
      alert.error("Erro ao deletar contato. Tente novamente mais tarde");
    } finally {
      setLoadingDeleteLead(false);
    }
  };

  const toggleBotState = async (leadId: string) => {
    setLoadingToggleBotState(true);
    try {
      await api.patch(`/leads/toggle-bot-state/${leadId}`);
      alert.success("Estado do robô alterado com sucesso!");
      loadLeads(false);
    } catch (error) {
      alert.error("Erro ao alterar estado do robô. Tente novamente mais tarde");
    } finally {
      setLoadingToggleBotState(false);
    }
  };

  const leadsToList = useMemo(() => {
    if (!search) {
      return leads;
    }
    return leads.filter(
      (lead) =>
        lead.name.toLowerCase().includes(search.toLowerCase()) ||
        lead.phone.includes(search.replace(/\s/g, ""))
    );
  }, [leads, search]);

  useEffect(() => {
    loadLeads();
  }, [loadLeads]);

  return (
    <Sidebar
      ref={sidebarRef}
      title={`Contatos - ${formatPhone(state.phone)}`}
      actions={
        <>
          <Button
            variant="outline"
            size="lg"
            onClick={cleanAllThreads}
            disabled={loading}
          >
            <ListRestart className="mr-2 h-4 w-4" />
            Limpar threads
          </Button>
        </>
      }
    >
      <div>
        <div className="flex gap-3">
          <Select onValueChange={(value) => setFilter(value)} value={filter}>
            <SelectTrigger>
              <SelectValue placeholder="Filtrar" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">Contatos de hoje</SelectItem>
              <SelectItem value="2">Últimos 2 dias</SelectItem>
              <SelectItem value="3">Últimos 3 dias</SelectItem>
              <SelectItem value="4">Últimos 4 dias</SelectItem>
              <SelectItem value="5">Últimos 5 dias</SelectItem>
              <SelectItem value="6">Últimos 6 dias</SelectItem>
              <SelectItem value="7">Última semana</SelectItem>
              <SelectItem value="15">Últimos 15 dias</SelectItem>
              <SelectItem value="30">Último mês</SelectItem>
              <SelectItem value="all">Todos os contatos</SelectItem>
            </SelectContent>
          </Select>
          <Input
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <Table className="mt-8">
          {(error || !leadsToList.length) && !loading && (
            <TableCaption className="mb-8">
              Nenhum contato encontrado para o filtro selecionado
            </TableCaption>
          )}
          {loading && (
            <TableCaption className="mb-8">
              <Loader2 className="animate-spin" /> Carregando contatos
            </TableCaption>
          )}
          <TableHeader>
            <TableRow>
              <TableHead className="min-w-[200px]">Nome</TableHead>
              <TableHead className="min-w-[200px]">Telefone</TableHead>
              <TableHead className="text-center">Estado</TableHead>
              <TableHead className="min-w-[200px]">Primeira conversa</TableHead>
              <TableHead className="min-w-[200px]">Ultima conversa</TableHead>
              <TableHead className="min-w-[200px] text-center">Ações</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody
            className={`${
              loadingToggleBotState || loadingDeleteLead
                ? "opacity-50 pointer-events-none"
                : ""
            }`}
          >
            {leadsToList.map((lead) => (
              <TableRow key={lead._id}>
                <TableCell>{lead.name}</TableCell>
                <TableCell>{formatPhone(lead.phone)}</TableCell>
                <TableCell>
                  <div className="flex items-center flex-col justify-center">
                    <Switch
                      className={`${
                        !lead.isBotAnswering &&
                        lead.isBotPausedByHuman &&
                        "data-[state=unchecked]:bg-yellow"
                      }`}
                      checked={lead.isBotAnswering}
                      onCheckedChange={() => toggleBotState(lead._id)}
                    />
                    <span className="text-xs text-gray text-center mt-2">
                      {!lead.isBotAnswering && lead.isBotPausedByHuman
                        ? "Robô pausado"
                        : lead.isBotAnswering
                        ? "Robô ligado"
                        : "Robô desligado"}
                      <br />
                    </span>
                  </div>
                </TableCell>
                <TableCell>{formatDate(lead.created_at)}</TableCell>
                <TableCell>{formatDate(lead.lastInteraction, true)}</TableCell>
                <TableCell>
                  <div className="flex items-center justify-center gap-2">
                    <Button
                      variant="ghost"
                      onClick={() => navigateToConversation(lead.phone)}
                    >
                      <MessageCircle size={16} />
                    </Button>

                    <Button
                      variant="ghost"
                      onClick={() => deleteLead(lead._id)}
                    >
                      <Trash size={16} className="text-red" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Sidebar>
  );
};

export default Leads;
